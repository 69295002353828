import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×6\\@75%1rm`}</p>
    <p>{`Glute Ham Raise 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 EMOM (alternating movements):`}</p>
    <p>{`minutes 1/3/5/7/9: Max Assault Bike Calories`}</p>
    <p>{`minutes 2-4/6/8/10: Max Air Squats`}</p>
    <p>{`score=total reps (1 calorie = 1 rep)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      